import { createSlice } from '@reduxjs/toolkit';

import {
  getUserData,
  changeUserData,
  subscriptionCreate,
  subscriptionUpdate,
  subscriptionCancel,
} from './operations';

const taskStats = {
  total: 0,
  aiPhotoshooting: 0,
  upscaling: 0,
  bgRemove: 0,
  fashionStore: 0,
};

let userDataInitialState = {
  id: '',
  email: '',
  credits: 0,
  first_name: null,
  last_name: null,
  subscription: {
    plan: {
      type: '',
      name: '',
      credits: 0,
    },
    created: '',
    expires: '',
    status: null,
  },
  api_key: null,
  intercom_hash: null,
  statistics: {
    payments: [{ date: '--.--.--', plan: '-', sum: '-' }],
    tasks: taskStats,
  },
  created: '',
  modified: '',
  isLoading: false,
  responseErrorMessage: null,
};

const handlePending = state => {
  state.responseErrorMessage = null;
  state.isLoading = true;
};

const handleRejected = (state, { payload }) => {
  state.responseErrorMessage = payload;
  state.isLoading = false;
};

export const userDataSlice = createSlice({
  name: 'userdata',
  initialState: userDataInitialState,

  reducers: {
    resetUserData(state) {
      state = userDataInitialState;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getUserData.pending, handlePending)
      .addCase(getUserData.rejected, handleRejected)
      .addCase(getUserData.fulfilled, (state, { payload }) => {
        state.id = payload.id;
        state.email = payload.email;
        state.credits = payload.credits;
        state.first_name = payload.first_name;

        if (payload.subscription !== null) {
          state.subscription.created = payload.subscription.created;
          state.subscription.expires = payload.subscription.expires;
          state.subscription.status = payload.subscription.status;
          state.subscription.plan.type = payload.subscription.plan.type;
          state.subscription.plan.name = payload.subscription.plan.name;
          state.subscription.plan.credits = payload.subscription.plan.credits;
        } else {
          state.subscription.created = null;
          state.subscription.expires = null;
          state.subscription.status = null;
          state.subscription.plan.type = null;
          state.subscription.plan.name = null;
          state.subscription.plan.credits = null;
        }

        state.api_key = payload.api_key;
        state.intercom_hash = payload.intercom_hash;
        state.created = payload.created;
        state.modified = payload.modified;
        state.isLoading = false;
        state.responseErrorMessage = null;
      })
      .addCase(changeUserData.pending, handlePending)
      .addCase(changeUserData.rejected, handleRejected)
      .addCase(changeUserData.fulfilled, (state, { payload }) => {
        for (let key in payload) {
          state[key] = payload[key];
        }
        state.isLoading = false;
      })
      .addCase(subscriptionCreate.pending, handlePending)
      .addCase(subscriptionCreate.rejected, handleRejected)
      .addCase(subscriptionCreate.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(subscriptionUpdate.pending, handlePending)
      .addCase(subscriptionUpdate.rejected, handleRejected)
      .addCase(subscriptionUpdate.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(subscriptionCancel.pending, handlePending)
      .addCase(subscriptionCancel.rejected, handleRejected)
      .addCase(subscriptionCancel.fulfilled, state => {
        state.subscription.status = 'pending_cancellation';
        state.isLoading = false;
      });
  },
});

export const { resetUserData } = userDataSlice.actions;
export const userDataReducer = userDataSlice.reducer;
