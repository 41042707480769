import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  selectCurrentCreditsNumber,
  selectPlanName,
  selectSubscriptionCreatedDate,
  selectSubscriptionExpirationDate,
  selectPlanCreditsNumber,
  selectPlanStatus,
} from '../../../redux/user-data/selectors';

import { subscriptionCancel } from '../../../redux/user-data/operations';

import { MODAL_META } from 'utils/constants';

import {
  ButtonByContent,
  TransparentButton,
} from 'components/common/Buttons/Buttons';
import Modal from 'components/common/modals/Modal';
import { ChooseOneOptionWithoutWarningTitle } from 'components/common/modals/ModalMessageTemplates/ModalMessageTemplates';
import icon from 'media/images/svgSprite.svg';

import {
  CreditsWrap,
  CreditsText,
  // Progress,
  ButtonText,
  PlanDescription,
  DescriptionItem,
  ItemTitle,
  ButtonSection,
  ExpirationLable,
  ExpirationIcon,
} from 'components/user/Balance/Balance.styled';

const Balance = ({ closeModal }) => {
  const [currentPlan, setCurrentPlan] = useState('-');
  const [expirationDate, setExpirationDate] = useState('-');
  const [paymentDate, setPaymentDate] = useState('-');
  const [balance, setBalance] = useState({
    current: 0,
    maxPossible: 0,
  });
  // const [usagePercentage, setUsagePercentage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const subscriptionStatus = useSelector(selectPlanStatus);
  const plan = useSelector(selectPlanName);
  const credits = useSelector(selectCurrentCreditsNumber);
  const planCredits = useSelector(selectPlanCreditsNumber);
  const subscriptionCreatedDate = useSelector(selectSubscriptionCreatedDate);
  const subscriptionExpirationDate = useSelector(
    selectSubscriptionExpirationDate
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // setBalance({ current: credits, maxPossible: planCredits });

    setBalance({ current: credits });
    if (subscriptionStatus === null) {
      return;
    }

    setCurrentPlan(plan);

    const date = formatDate(subscriptionCreatedDate);
    setPaymentDate(date);

    const expirationdate = formatDate(subscriptionExpirationDate);
    setExpirationDate(expirationdate);

    // let currentPercent = null;

    // if (planCredits === null) {
    //   currentPercent = 100;
    // } else {
    //   currentPercent = (credits * 100) / planCredits;
    // }

    // setUsagePercentage(currentPercent);
  }, [
    credits,
    plan,
    planCredits,
    subscriptionCreatedDate,
    subscriptionExpirationDate,
    subscriptionStatus,
  ]);

  function handleUpgradePlan(e) {
    e.stopPropagation();
    navigate('pricing');
    closeModal();
  }

  async function handleCancelSubscription(e, actionType) {
    e.stopPropagation();

    switch (actionType) {
      case 'decline':
        setIsModalOpen(false);
        break;

      case 'confirm':
        await dispatch(subscriptionCancel());

        setIsModalOpen(false);
        break;

      default:
        setIsModalOpen(false);
    }
  }

  function formatDate(date) {
    const dateTime = new Date(date);
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0');
    const day = dateTime.getDate();

    const formattedDate = `${day}.${month}.${year}`;

    return formattedDate;
  }

  return (
    <>
      <CreditsWrap>
        {/* {balance.maxPossible === null ? (
          <CreditsText>unlimited credits</CreditsText>
        ) : (
          <CreditsText>
            {balance.current}/{balance.maxPossible} left
          </CreditsText>
        )}
        <Progress $used={usagePercentage}></Progress> */}
        <CreditsText>{balance.current} credits left</CreditsText>
      </CreditsWrap>

      <PlanDescription>
        <DescriptionItem>
          <ItemTitle>Current plan name</ItemTitle>
          <p>{currentPlan}</p>
          {subscriptionStatus === 'pending_cancellation' && (
            <ExpirationLable>
              <span>Cancels {expirationDate}</span>
              <ExpirationIcon>
                <use href={`${icon}#icon-clock`}></use>
              </ExpirationIcon>
            </ExpirationLable>
          )}
        </DescriptionItem>
        <DescriptionItem>
          <ItemTitle>
            {subscriptionStatus === 'pending_cancellation'
              ? 'Current plan expiration date'
              : 'Next payment date'}
          </ItemTitle>
          <p>{expirationDate}</p>
        </DescriptionItem>
        <DescriptionItem>
          <ItemTitle>Last payment date</ItemTitle>
          <p>{paymentDate}</p>
        </DescriptionItem>
      </PlanDescription>

      <ButtonSection>
        <ButtonByContent
          gbColor={'primary_color'}
          textColor={'light'}
          handleClick={handleUpgradePlan}
        >
          <ButtonText>
            {subscriptionStatus === 'cancelled' || !subscriptionStatus
              ? MODAL_META.subscription.buyButton
              : MODAL_META.subscription.getButton}
          </ButtonText>
        </ButtonByContent>
        <TransparentButton
          isDisabled={!subscriptionStatus || subscriptionStatus !== 'active'}
          handleClick={() => setIsModalOpen(true)}
        >
          {MODAL_META.subscription.cancelling.confirmButton}
        </TransparentButton>
      </ButtonSection>

      {isModalOpen && (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <ChooseOneOptionWithoutWarningTitle
            title={MODAL_META.subscription.cancelling.title}
            firstButtonText={MODAL_META.subscription.cancelling.declineButton}
            secondButtonText={MODAL_META.subscription.cancelling.confirmButton}
            closeModalWithFirstOption={e =>
              handleCancelSubscription(e, 'decline')
            }
            closeModalWithSecondOption={e =>
              handleCancelSubscription(e, 'confirm')
            }
          />
        </Modal>
      )}
    </>
  );
};

export default Balance;
