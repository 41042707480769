export const selectUserId = state => state.userdata.id;

export const selectUserEmail = state => state.userdata.email;

export const selectCurrentCreditsNumber = state => state.userdata.credits;

export const selectFirstName = state => state.userdata.first_name;

export const selectLastName = state => state.userdata.last_name;

export const selectPlanName = state => state.userdata.subscription.plan.name;
export const selectPlanType = state => state.userdata.subscription.plan.type;

export const selectPlanCreditsNumber = state =>
  state.userdata.subscription.plan.credits;

export const selectPlanStatus = state => state.userdata.subscription.status;

export const selectSubscriptionCreatedDate = state =>
  state.userdata.subscription.created;

export const selectSubscriptionExpirationDate = state =>
  state.userdata.subscription.expires;

export const selectApiKey = state => state.userdata.api_key;

export const selectIntercomHash = state => state.userdata.intercom_hash;

//----------statistics-------------

export const selectPaymentStats = state => state.userdata.statistics.payments;

export const selectTaskStats = state => state.userdata.statistics.tasks;

//----------------------------------

export const selectUserDatatIsLoading = state => state.userdata.isLoading;

export const selectUserDatatErrorMessage = state =>
  state.userdata.responseErrorMessage;
