import icon from 'media/images/svgSprite.svg';
import {
  Wrapper,
  LoaderWrap,
  LoaderIcon,
  LoaderIconPetal,
} from 'components/smallStatic/Loader/Loader.styled';

const loaderDelay = 200;
const loaderAnimationTime = loaderDelay * 2 * 6;

const Loader = () => {
  return (
    <Wrapper>
      <LoaderWrap>
        <LoaderIconPetal
          key={'petal6'}
          $disappearance={loaderDelay * 5}
          $animationTime={loaderAnimationTime}
        >
          <use href={`${icon}#icon-petal6`}></use>
        </LoaderIconPetal>
        <LoaderIconPetal
          key={'petal5'}
          $disappearance={loaderDelay * 4}
          $animationTime={loaderAnimationTime}
        >
          <use href={`${icon}#icon-petal5`}></use>
        </LoaderIconPetal>
        <LoaderIconPetal
          key={'petal4'}
          $disappearance={loaderDelay * 3}
          $animationTime={loaderAnimationTime}
        >
          <use href={`${icon}#icon-petal4`}></use>
        </LoaderIconPetal>
        <LoaderIconPetal
          key={'petal3'}
          $disappearance={loaderDelay * 2}
          $animationTime={loaderAnimationTime}
        >
          <use href={`${icon}#icon-petal3`}></use>
        </LoaderIconPetal>
        <LoaderIconPetal
          key={'petal2'}
          $disappearance={loaderDelay}
          $animationTime={loaderAnimationTime}
        >
          <use href={`${icon}#icon-petal2`}></use>
        </LoaderIconPetal>
        <LoaderIconPetal
          key={'petal1'}
          $disappearance={0}
          $animationTime={loaderAnimationTime}
        >
          <use href={`${icon}#icon-petal1`}></use>
        </LoaderIconPetal>
        <LoaderIcon key={'center'}>
          <use href={`${icon}#icon-center`}></use>
        </LoaderIcon>
      </LoaderWrap>
    </Wrapper>
  );
};

export default Loader;
