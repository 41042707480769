import { useState } from 'react';

import {
  DeleteButton,
  ExtraButtonIcon,
} from 'components/common/Buttons/Buttons';
import icon from 'media/images/svgSprite.svg';

import {
  AddImgsList,
  ImgWrap,
  ImageContainer,
  ClothesImg,
  BrokenFileBlur,
  BrokenFileIcon,
  BrokenFileMessage,
  GalleryToolsIcon,
} from 'components/common/create/CreateClothesList/CreateClothesList.styled';

const errorsText = {
  imageFormatInvalid: 'Image format is invalid',
  imageResolutionTooLarge: 'Image resolution too large',
  imageSizeTooLarge: 'Image size too large',
};

const CreateClothesList = ({ fileList, handleDeleteImage }) => {
  const [isMessageShown, setIsMessageShown] = useState(false);
  const [imageId, setImageId] = useState('');
  const [messagPosition, setMessagPosition] = useState({ left: 0 });

  function onShowMessage(id) {
    if (imageId !== id) {
      const leftPosIndexes = [0, 1, 5, 6];
      const rightPosIndexes = [3, 4, 8, 9];
      const elemIndex = fileList.findIndex(item => item.id === id);

      if (leftPosIndexes.includes(elemIndex)) {
        setMessagPosition({ left: 0 });
      } else if (rightPosIndexes.includes(elemIndex)) {
        setMessagPosition({ right: 0 });
      } else {
        setMessagPosition({ left: '50%', transform: 'translateX(-50%)' });
      }

      setImageId(id);
      setIsMessageShown(true);
    } else {
      setImageId('');
      setIsMessageShown(false);
    }
  }

  return (
    <>
      <AddImgsList>
        {fileList.length > 0 &&
          fileList.map(item => {
            return (
              <ImgWrap key={item.id}>
                <ImageContainer>
                  {item.imageType === 'gallery' ? (
                    <ClothesImg src={item.result_image} alt="your clothes" />
                  ) : (
                    <ClothesImg
                      src={URL.createObjectURL(item)}
                      alt="your clothes"
                    />
                  )}
                </ImageContainer>

                {item.error ? (
                  <BrokenFileBlur
                    type="button"
                    onClick={() => onShowMessage(item.id)}
                  >
                    <BrokenFileIcon>
                      <use href={`${icon}#icon-broken-file`}></use>
                    </BrokenFileIcon>
                  </BrokenFileBlur>
                ) : (
                  <DeleteButton
                    handleDeleteImage={handleDeleteImage}
                    id={item.id}
                    $isbig={'medium'}
                  />
                )}

                {isMessageShown && imageId === item.id && (
                  <BrokenFileMessage style={messagPosition}>
                    <h5>{errorsText[item.error.type]}</h5>
                    <p>Please replace the image</p>
                    <section>
                      <ExtraButtonIcon
                        disableStatus={false}
                        handleDeleteElement={() => handleDeleteImage(item.id)}
                      >
                        <GalleryToolsIcon>
                          <use href={`${icon}#icon-trashbox`}></use>
                        </GalleryToolsIcon>
                      </ExtraButtonIcon>
                    </section>
                  </BrokenFileMessage>
                )}
              </ImgWrap>
            );
          })}
      </AddImgsList>
    </>
  );
};

export default CreateClothesList;
