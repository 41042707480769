import styled, { keyframes } from 'styled-components';
import { vars } from 'utils/variables';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Content = styled.div`
  max-width: 330px;
  padding: 40px 44px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  border-radius: ${vars.mainBorderRadius};
  background-color: ${vars.light100};
  animation: 700ms ${fadeIn} ease;

  box-shadow: ${vars.modalBoxShadow};

  @media screen and (min-width: 768px) {
    max-width: 468px;
    padding: 70px 90px;
    gap: 24px;
  }
`;

export const ContentWithImage = styled(Content)`
  max-width: 356px;
  padding: 40px 14px;

  @media screen and (min-width: 768px) {
    max-width: 502px;
    padding: 70px 30px;
  }
`;

export const ModalTitle = styled.h3`
  color: ${vars.darkBlack100};

  font-size: 19px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  @media screen and (min-width: 768px) {
    font-size: 27px;
    line-height: 32px;
  }
`;

export const ModalText = styled.p`
  margin-top: 5px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  color: ${vars.darkTransparent70};

  font-size: 11px;
  font-style: italic;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  @media screen and (min-width: 768px) {
    font-size: 15px;
    line-height: 24px;
  }
`;

export const ModalButtonWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 768px) {
    gap: 16px;
    flex-direction: row;
  }
`;

export const ColumnOfButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
