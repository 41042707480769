import styled from 'styled-components';
import { vars } from 'utils/variables';

export const AddImgsList = styled.ul`
  width: 100%;

  padding: 0;

  display: flex;
  flex-wrap: wrap;
  gap: 7px;

  @media screen and (min-width: 768px) {
    gap: 8px;
  }
`;

export const ImgWrap = styled.li`
  width: 60px;
  height: 64px;

  position: relative;

  background-color: ${vars.light100};

  border-radius: 16px;

  @media screen and (min-width: 768px) {
    width: 82px;
    height: 87px;
  }
`;

export const ImageContainer = styled.section`
  width: 100%;
  height: 100%;

  border-radius: 16px;
  border: 1px solid ${vars.primaryRose};
  overflow: hidden;
`;

export const ClothesImg = styled.img`
  width: 100%;
  height: 100%;

  object-fit: contain;
`;

//--------------Broken File----------------

export const BrokenFileBlur = styled.button`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  background-color: transparent;
  border-radius: 16px;
  transition: background-color ${vars.transitionTimingFunction};

  &:hover {
    background-color: ${vars.lightTransparent70};
  }
`;

export const BrokenFileIcon = styled.svg`
  width: 24px;
  height: 24px;

  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, -20%);

  fill: ${vars.secondaryMediumRed};

  @media screen and (min-width: 768px) {
    width: 32px;
    height: 32px;
  }
`;

export const BrokenFileMessage = styled.section`
  min-width: 254px;
  padding: 16px 20px;

  position: absolute;
  bottom: 69px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${vars.dark70};
  color: ${vars.light100};
  border-radius: 24px;
  box-shadow: ${vars.boxShadow};
  z-index: 2;

  & > p {
    margin-top: 4px;

    font-size: 11px;
    font-weight: 400;
    line-height: normal;
  }

  & > section {
    width: 80px;
    margin-top: 12px;
  }

  @media screen and (min-width: 768px) {
    bottom: 90px;
  }
`;

export const GalleryToolsIcon = styled.svg`
  width: 24px;
  height: 24px;

  fill: ${vars.darkBlack100};

  transition: fill ${vars.transitionTimingFunction};
`;
