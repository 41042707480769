import axios from 'axios';
import { fetchUserAttributes, updateUserAttribute } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  selectCurrentCreditsNumber,
  selectPlanCreditsNumber,
  selectPlanStatus,
} from '../../../redux/user-data/selectors';

import useWindowWidth from 'hooks/useWindowWidth';
import handleError from 'services/handleError';

import {
  COOKIE_DOMAIN_NAME,
  USER_POOL_CLIENT_ID,
  BASE_URL,
  API__PREFIX,
  API__VERSION,
} from 'utils/configs';

import Logo from 'components/interactiveComponents/Logo';
import { IconButton } from 'components/common/Buttons/Buttons';
import icon from 'media/images/svgSprite.svg';
import ModalLight from 'components/common/modals/ModalLight';
import Modal from 'components/common/modals/Modal';
import MainMenu from 'components/SharedLayout/MainMenu';
import UserMenu from 'components/user/UserMenu';
import UserCabinet from 'components/user/UserCabinet';
import BetaLable from 'components/interactiveComponents/BetaLable';
import WelcomeVideo from 'components/WelcomeVideo';

import {
  HeaderWrap,
  HeaderContainer,
  BurgerIconWrap,
  HeaderLogoSection,
  BigLogoWrap,
  SmallLogoWrap,
  NavWrap,
  MenuIcon,
  CreditsWrap,
  CreditsText,
  // Progress,
  UserIcon,
} from 'components/SharedLayout/Header/Header.styled';

const tokenArr = ['accessToken', 'idToken', 'refreshToken'];
const tokenCompare = {
  accessToken: 'access_token',
  idToken: 'id_token',
  refreshToken: 'refresh_token',
};

const Header = () => {
  const [isNewUser, setIsNewUser] = useState(false);
  const [balance, setBalance] = useState({
    current: 0,
    maxPossible: 0,
  });
  // const [usagePercentage, setUsagePercentage] = useState(0);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState('open');
  const [opacity, setOpacity] = useState(1);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isUserCabinetOpen, setIsUserCabinetOpen] = useState(false);

  const subscriptionStatus = useSelector(selectPlanStatus);
  const credits = useSelector(selectCurrentCreditsNumber);
  const planCredits = useSelector(selectPlanCreditsNumber);

  const width = useWindowWidth();
  const isMobile = width < 767;

  const { user } = useAuthenticator(context => [context.user]);
  axios.defaults.baseURL = `${BASE_URL}`;

  useEffect(() => {
    tokenArr.forEach(item => {
      const tokenValue = localStorage.getItem(
        `CognitoIdentityServiceProvider.${USER_POOL_CLIENT_ID}.${user.username}.${item}`
      );

      document.cookie = `${tokenCompare[item]}=${tokenValue};path=/;domain=${COOKIE_DOMAIN_NAME};Secure;`;

      try {
        axios.post(`${API__PREFIX}${API__VERSION}/user/ensure`);
      } catch (error) {
        const { status, data } = error.response;
        handleError(status, data);
      }
    });
  }, [user.username]);

  useEffect(() => {
    // if (subscriptionStatus === null) {
    //   return;
    // }

    setBalance({
      current: credits,
      maxPossible: planCredits ? planCredits : 0,
    });

    // let currentPercent = null;

    // if (planCredits === null) {
    //   currentPercent = 100;
    // } else {
    //   currentPercent = (credits * 100) / planCredits;
    // }

    // setUsagePercentage(currentPercent);
  }, [credits, planCredits, subscriptionStatus]);

  useEffect(() => {
    if (user.username) {
      checkIfNewUser();
    }
  }, [user.username]);

  async function checkIfNewUser() {
    try {
      const userAttributes = await fetchUserAttributes();

      if (
        userAttributes['custom:firstLogin'] === '1' ||
        !userAttributes['custom:firstLogin']
      ) {
        setIsNewUser(true);
      } else {
        setIsNewUser(false);
      }
    } catch (error) {
      console.error('Error fetching user attributes');
    }
  }

  function onOpenOrCloseUserMenu() {
    setIsUserMenuOpen(!isUserMenuOpen);
  }

  function onOpenMenu() {
    setIsMenuOpen('open');
    setOpacity(1);
    setIsMainMenuOpen(true);
    setIsUserMenuOpen(false);
  }

  function onCloseMenu() {
    setIsMenuOpen('close');

    setTimeout(() => {
      setOpacity(0);
    }, 290);

    setTimeout(() => {
      setIsMainMenuOpen(false);
    }, 300);
  }

  function OpenCabinetFromUserMenu() {
    setIsUserMenuOpen(false);
    setIsUserCabinetOpen(true);
  }

  async function onCloseWelcomeVideo() {
    try {
      await updateUserAttribute({
        userAttribute: { attributeKey: 'custom:firstLogin', value: '0' },
      });

      setIsNewUser(false);
    } catch (error) {
      console.error('Error updating user attributes');
    }
  }

  return (
    <>
      <HeaderWrap id="header">
        <HeaderContainer>
          <BurgerIconWrap onClick={onOpenMenu}>
            <MenuIcon>
              <use href={`${icon}#icon-burger-menu_2`}></use>
            </MenuIcon>
          </BurgerIconWrap>

          <HeaderLogoSection>
            {isMobile ? (
              <SmallLogoWrap>
                <Logo iconName={'flower'} />
              </SmallLogoWrap>
            ) : (
              <BigLogoWrap>
                <Logo iconName={'logo'} />
              </BigLogoWrap>
            )}
            <BetaLable
              topLablePos={'4px'}
              rightLablePos={'-23px'}
              topMessagePos={'61px'}
              bottomMessagePos={'auto'}
              leftMessagePos={'-55px'}
              translateXValue={'0'}
              lableLocation={'header'}
            />
          </HeaderLogoSection>

          <NavWrap>
            {/* <CreditsWrap to={'pricing'}>
              {balance.maxPossible === null ? (
                <CreditsText>unlimited credits</CreditsText>
              ) : (
                <CreditsText>
                  {balance.current}/{balance.maxPossible} left
                </CreditsText>
              )}
              <Progress $used={usagePercentage}></Progress>
            </CreditsWrap> */}
            <CreditsWrap to={'pricing'}>
              <CreditsText>{balance.current} credits left</CreditsText>
            </CreditsWrap>
            <UserIcon>
              <IconButton
                btnColor={'black_transparent'}
                handleClick={onOpenOrCloseUserMenu}
              >
                <MenuIcon>
                  <use href={`${icon}#icon-user`}></use>
                </MenuIcon>
              </IconButton>
            </UserIcon>
          </NavWrap>
        </HeaderContainer>
      </HeaderWrap>

      {isMainMenuOpen && (
        <MainMenu
          isClosing={isMenuOpen}
          isVisible={opacity}
          onCloseMenu={onCloseMenu}
        />
      )}
      {isUserMenuOpen && (
        <ModalLight closeModal={onOpenOrCloseUserMenu}>
          <UserMenu
            closeModal={onOpenOrCloseUserMenu}
            onOpenUserCabinet={OpenCabinetFromUserMenu}
            onCloseUserCabinet={() => setIsUserCabinetOpen(false)}
          />
        </ModalLight>
      )}
      {isUserCabinetOpen && (
        <ModalLight closeModal={() => setIsUserCabinetOpen(false)}>
          <UserCabinet closeModal={() => setIsUserCabinetOpen(false)} />
        </ModalLight>
      )}
      {isNewUser && (
        <Modal closeModal={onCloseWelcomeVideo}>
          <WelcomeVideo onCloseWelcomeModal={onCloseWelcomeVideo} />
        </Modal>
      )}
    </>
  );
};

export default Header;
